.nav-active {
  color: grey;
  font-weight: bold;
}

.content-container {
  min-height: calc(100vh - 200px);
  /* padding-top: 38px; */
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

/* FOR SEGMENTED CONTROL */
* {
  box-sizing: border-box;
}

/* Demo only */
/* body {
  background: #f1f1f1;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.selected-item {
  text-align: center;
}

.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
  /* margin: 100px 0 25px; */
  width: 100%;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  max-width: 500px;
  padding: 10px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  width: 100%; 
  /* min-width: 120px; */
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 600;
  width: 200px;
  font-size: small;
  padding: 2px;
  /* padding-left: 10px;
  padding-right: 10px; */
  transition: color 0.5s ease;
}

.segment.active label {
  color: #fff;
}

.controls::before {
  content: "";
  background: #8170F5;
  border-radius: 20px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}


/*.App {*/
/*  background: #2b272e;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

/*footer {*/
/*  position: fixed;*/
/*  margin-top: auto;*/
/*}*/